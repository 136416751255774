<template>
  <div class="store__currency">
    <v-row class="my-7 mx-0">
      <v-col cols="12" md="4">
        <h3>Currency</h3>
      </v-col>
      <v-col cols="12" md="8">
        <v-row class="elevation-form px-2 py-2">
          <v-col cols="12">
            <div class="d-flex align-center">
              <h4>Currency</h4>
            </div>
            <v-autocomplete
              v-model="$parent.general.language"
              :items="[
                { name: 'VND', value: 'vi' },
                { name: 'Dollar', value: 'en' },
                { name: 'EURO', value: 'eur' },
              ]"
              item-text="name"
              item-value="value"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import currencies from '@/mixins/currencies';

export default {
  mixins: [currencies],
  data() {
    return {
      show: false,
    };
  },
};
</script>
