<template>
  <div class="store__currency">
    <v-row class="my-7 mx-0">
      <v-col cols="12" md="4">
        <h3>Scope not served</h3>
      </v-col>
      <v-col cols="12" md="8">
        <v-row class="elevation-form px-2 py-2">
          <v-col cols="12">
            <div class="d-flex align-center">
              <h4>Countries</h4>
            </div>
            <v-text-field readonly value="USA"></v-text-field>
            <h4>Sates</h4>
            <v-autocomplete
              v-model="$parent.general.blacklistStates"
              :items="states"
              item-text="name"
              item-value="value"
              multiple
              clearable
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import usStates from '@/const/us_states.json';
export default {
  data() {
    const states = Object.entries(usStates).map(([value, name]) => ({ value, name }));
    return {
      states,
    };
  },
};
</script>
