<template>
  <div class="store__detail">
    <v-row class="my-7 mx-0">
      <v-col cols="12" md="4">
        <h3>Standards and formats</h3>
        <p>Standards and formats are used to calculate product prices, shipping weights, and order times.</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-row class="elevation-form px-2 py-2">
          <v-col cols="12">
            <h4>Timezone</h4>
            <v-autocomplete
              :items="timeZones"
              item-text="text"
              item-value="value"
              v-model="$parent.general.standardTimezone"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <InputNest label="Unit weight" :model="['general', 'standardDefaultWeightUnit']" />
          </v-col>
          <v-col cols="6">
            <InputNest
              typeComponent="select"
              itemText="name"
              itemValue="value"
              :itemsSelect="weightUnits"
              label="Default weight unit"
              :model="['general', 'standardUnitWeight']"
            />
          </v-col>
          <v-col cols="12">
            <h4>EDIT ORDER ID FORMAT (OPTIONAL)</h4>
            <p>
              Order numbers start at #1001 by default. While you can’t change the order number itself, you can add a
              prefix or suffix to create IDs like "EN1001" or "1001-A."
            </p>
          </v-col>
          <v-col cols="6">
            <InputNest label="Prefix" :model="['general', 'editOrderIdPrfeix']" />
          </v-col>
          <v-col cols="6">
            <InputNest label="Suffix" :model="['general', 'editOrderIdSuffix']" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import timezones from '@/mixins/timezones';

export default {
  mixins: [timezones],
  data() {
    return {
      weightUnits: [
        {
          name: 'Pound (lb)',
          value: 'lb',
        },
        {
          name: 'Ounce (oz)',
          value: 'oz',
        },
        {
          name: 'Kilogram (kg)',
          value: 'kg',
        },
        {
          name: 'Gram (g)',
          value: 'g',
        },
      ],
    };
  },
};
</script>
