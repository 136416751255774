export default {
  data() {
    return {
      timeZones: [
        {
          value: 'Etc/GMT+12',
          text: '(GMT-12:00) International Date Line West',
        },
        {
          value: 'Pacific/Pago_Pago',
          text: '(GMT-11:00) American Samoa',
        },
        {
          value: 'Pacific/Midway',
          text: '(GMT-11:00) Midway Island',
        },
        {
          value: 'Pacific/Honolulu',
          text: '(GMT-10:00) Hawaii',
        },
        {
          value: 'Pacific/Marquesas',
          text: '(GMT-09:30) Pacific/Marquesas',
        },
        {
          value: 'Pacific/Gambier',
          text: '(GMT-09:00) Pacific/Gambier',
        },
        {
          value: 'America/Juneau',
          text: '(GMT-09:00) Alaska',
        },
        {
          value: 'America/Los_Angeles',
          text: '(GMT-08:00) Pacific Time (US & Canada)',
        },
        {
          value: 'America/Tijuana',
          text: '(GMT-08:00) Tijuana',
        },
        {
          value: 'America/Phoenix',
          text: '(GMT-07:00) Arizona',
        },
        {
          value: 'America/Chihuahua',
          text: '(GMT-07:00) Chihuahua',
        },
        {
          value: 'America/Mazatlan',
          text: '(GMT-07:00) Mazatlan',
        },
        {
          value: 'America/Denver',
          text: '(GMT-07:00) Mountain Time (US & Canada)',
        },
        {
          value: 'America/Guatemala',
          text: '(GMT-06:00) Central America',
        },
        {
          value: 'America/Chicago',
          text: '(GMT-06:00) Central Time (US & Canada)',
        },
        {
          value: 'America/Mexico_City',
          text: '(GMT-06:00) Guadalajara, Mexico City',
        },
        {
          value: 'America/Monterrey',
          text: '(GMT-06:00) Monterrey',
        },
        {
          value: 'America/Regina',
          text: '(GMT-06:00) Saskatchewan',
        },
        {
          value: 'America/Bogota',
          text: '(GMT-05:00) Bogota',
        },
        {
          value: 'America/New_York',
          text: '(GMT-05:00) Eastern Time (US & Canada)',
        },
        {
          value: 'America/Indiana/Indianapolis',
          text: '(GMT-05:00) Indiana (East)',
        },
        {
          value: 'America/Lima',
          text: '(GMT-05:00) Lima, Quito',
        },
        {
          value: 'America/Halifax',
          text: '(GMT-04:00) Atlantic Time (Canada)',
        },
        {
          value: 'America/Caracas',
          text: '(GMT-04:00) Caracas',
        },
        {
          value: 'America/Guyana',
          text: '(GMT-04:00) Georgetown',
        },
        {
          value: 'America/La_Paz',
          text: '(GMT-04:00) La Paz',
        },
        {
          value: 'America/Puerto_Rico',
          text: '(GMT-04:00) Puerto Rico',
        },
        {
          value: 'America/Santiago',
          text: '(GMT-04:00) Santiago',
        },
        {
          value: 'America/St_Johns',
          text: '(GMT-02:30) Newfoundland',
        },
        {
          value: 'America/Sao_Paulo',
          text: '(GMT-03:00) Brasilia',
        },
        {
          value: 'America/Argentina/Buenos_Aires',
          text: '(GMT-03:00) Buenos Aires',
        },
        {
          value: 'America/Godthab',
          text: '(GMT-02:00) Greenland',
        },
        {
          value: 'America/Montevideo',
          text: '(GMT-03:00) Montevideo',
        },
        {
          value: 'Atlantic/South_Georgia',
          text: '(GMT-02:00) Mid-Atlantic',
        },
        {
          value: 'Atlantic/Azores',
          text: '(GMT+00:00) Azores',
        },
        {
          value: 'Atlantic/Cape_Verde',
          text: '(GMT-01:00) Cape Verde Is.',
        },
        {
          value: 'Africa/Casablanca',
          text: '(GMT+01:00) Casablanca',
        },
        {
          value: 'Europe/Dublin',
          text: '(GMT+01:00) Dublin',
        },
        {
          value: 'Europe/London',
          text: '(GMT+01:00) Edinburgh, London',
        },
        {
          value: 'Europe/Lisbon',
          text: '(GMT+01:00) Lisbon',
        },
        {
          value: 'Africa/Monrovia',
          text: '(GMT+00:00) Monrovia',
        },
        {
          value: 'Etc/UTC',
          text: '(GMT+00:00) UTC',
        },
        {
          value: 'Europe/Amsterdam',
          text: '(GMT+02:00) Amsterdam',
        },
        {
          value: 'Europe/Belgrade',
          text: '(GMT+02:00) Belgrade',
        },
        {
          value: 'Europe/Berlin',
          text: '(GMT+02:00) Berlin',
        },
        {
          value: 'Europe/Zurich',
          text: '(GMT+02:00) Bern, Zurich',
        },
        {
          value: 'Europe/Bratislava',
          text: '(GMT+02:00) Bratislava',
        },
        {
          value: 'Europe/Brussels',
          text: '(GMT+02:00) Brussels',
        },
        {
          value: 'Europe/Budapest',
          text: '(GMT+02:00) Budapest',
        },
        {
          value: 'Europe/Copenhagen',
          text: '(GMT+02:00) Copenhagen',
        },
        {
          value: 'Europe/Ljubljana',
          text: '(GMT+02:00) Ljubljana',
        },
        {
          value: 'Europe/Madrid',
          text: '(GMT+02:00) Madrid',
        },
        {
          value: 'Europe/Paris',
          text: '(GMT+02:00) Paris',
        },
        {
          value: 'Europe/Prague',
          text: '(GMT+02:00) Prague',
        },
        {
          value: 'Europe/Rome',
          text: '(GMT+02:00) Rome',
        },
        {
          value: 'Europe/Sarajevo',
          text: '(GMT+02:00) Sarajevo',
        },
        {
          value: 'Europe/Skopje',
          text: '(GMT+02:00) Skopje',
        },
        {
          value: 'Europe/Stockholm',
          text: '(GMT+02:00) Stockholm',
        },
        {
          value: 'Europe/Vienna',
          text: '(GMT+02:00) Vienna',
        },
        {
          value: 'Europe/Warsaw',
          text: '(GMT+02:00) Warsaw',
        },
        {
          value: 'Africa/Algiers',
          text: '(GMT+01:00) West Central Africa',
        },
        {
          value: 'Europe/Zagreb',
          text: '(GMT+02:00) Zagreb',
        },
        {
          value: 'Europe/Athens',
          text: '(GMT+03:00) Athens',
        },
        {
          value: 'Europe/Bucharest',
          text: '(GMT+03:00) Bucharest',
        },
        {
          value: 'Africa/Cairo',
          text: '(GMT+02:00) Cairo',
        },
        {
          value: 'Africa/Harare',
          text: '(GMT+02:00) Harare',
        },
        {
          value: 'Europe/Helsinki',
          text: '(GMT+03:00) Helsinki',
        },
        {
          value: 'Asia/Jerusalem',
          text: '(GMT+03:00) Jerusalem',
        },
        {
          value: 'Europe/Kaliningrad',
          text: '(GMT+02:00) Kaliningrad',
        },
        {
          value: 'Europe/Kiev',
          text: '(GMT+03:00) Kyiv',
        },
        {
          value: 'Africa/Johannesburg',
          text: '(GMT+02:00) Pretoria',
        },
        {
          value: 'Europe/Riga',
          text: '(GMT+03:00) Riga',
        },
        {
          value: 'Europe/Sofia',
          text: '(GMT+03:00) Sofia',
        },
        {
          value: 'Europe/Tallinn',
          text: '(GMT+03:00) Tallinn',
        },
        {
          value: 'Europe/Vilnius',
          text: '(GMT+03:00) Vilnius',
        },
        {
          value: 'Asia/Baghdad',
          text: '(GMT+03:00) Baghdad',
        },
        {
          value: 'Europe/Istanbul',
          text: '(GMT+03:00) Istanbul',
        },
        {
          value: 'Asia/Kuwait',
          text: '(GMT+03:00) Kuwait',
        },
        {
          value: 'Europe/Minsk',
          text: '(GMT+03:00) Minsk',
        },
        {
          value: 'Europe/Moscow',
          text: '(GMT+03:00) Moscow, St. Petersburg',
        },
        {
          value: 'Africa/Nairobi',
          text: '(GMT+03:00) Nairobi',
        },
        {
          value: 'Asia/Riyadh',
          text: '(GMT+03:00) Riyadh',
        },
        {
          value: 'Europe/Volgograd',
          text: '(GMT+04:00) Volgograd',
        },
        {
          value: 'Asia/Tehran',
          text: '(GMT+04:30) Tehran',
        },
        {
          value: 'Asia/Muscat',
          text: '(GMT+04:00) Abu Dhabi, Muscat',
        },
        {
          value: 'Asia/Baku',
          text: '(GMT+04:00) Baku',
        },
        {
          value: 'Europe/Samara',
          text: '(GMT+04:00) Samara',
        },
        {
          value: 'Asia/Tbilisi',
          text: '(GMT+04:00) Tbilisi',
        },
        {
          value: 'Asia/Yerevan',
          text: '(GMT+04:00) Yerevan',
        },
        {
          value: 'Asia/Kabul',
          text: '(GMT+04:30) Kabul',
        },
        {
          value: 'Asia/Yekaterinburg',
          text: '(GMT+05:00) Ekaterinburg',
        },
        {
          value: 'Asia/Karachi',
          text: '(GMT+05:00) Islamabad, Karachi',
        },
        {
          value: 'Asia/Tashkent',
          text: '(GMT+05:00) Tashkent',
        },
        {
          value: 'Asia/Kolkata',
          text: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        },
        {
          value: 'Asia/Colombo',
          text: '(GMT+05:30) Sri Jayawardenepura',
        },
        {
          value: 'Asia/Kathmandu',
          text: '(GMT+05:45) Kathmandu',
        },
        {
          value: 'Asia/Almaty',
          text: '(GMT+06:00) Almaty',
        },
        {
          value: 'Asia/Dhaka',
          text: '(GMT+06:00) Astana, Dhaka',
        },
        {
          value: 'Asia/Urumqi',
          text: '(GMT+06:00) Urumqi',
        },
        {
          value: 'Asia/Rangoon',
          text: '(GMT+06:30) Rangoon',
        },
        {
          value: 'Asia/Bangkok',
          text: '(GMT+07:00) Bangkok, Hanoi',
        },
        {
          value: 'Asia/Jakarta',
          text: '(GMT+07:00) Jakarta',
        },
        {
          value: 'Asia/Krasnoyarsk',
          text: '(GMT+07:00) Krasnoyarsk',
        },
        {
          value: 'Asia/Novosibirsk',
          text: '(GMT+07:00) Novosibirsk',
        },
        {
          value: 'Asia/Shanghai',
          text: '(GMT+08:00) Beijing',
        },
        {
          value: 'Asia/Chongqing',
          text: '(GMT+08:00) Chongqing',
        },
        {
          value: 'Asia/Hong_Kong',
          text: '(GMT+08:00) Hong Kong',
        },
        {
          value: 'Asia/Irkutsk',
          text: '(GMT+08:00) Irkutsk',
        },
        {
          value: 'Asia/Kuala_Lumpur',
          text: '(GMT+08:00) Kuala Lumpur',
        },
        {
          value: 'Australia/Perth',
          text: '(GMT+08:00) Perth',
        },
        {
          value: 'Asia/Singapore',
          text: '(GMT+08:00) Singapore',
        },
        {
          value: 'Asia/Taipei',
          text: '(GMT+08:00) Taipei',
        },
        {
          value: 'Asia/Ulaanbaatar',
          text: '(GMT+08:00) Ulaanbaatar',
        },
        {
          value: 'Asia/Tokyo',
          text: '(GMT+09:00) Osaka, Sapporo, Tokyo',
        },
        {
          value: 'Asia/Seoul',
          text: '(GMT+09:00) Seoul',
        },
        {
          value: 'Asia/Yakutsk',
          text: '(GMT+09:00) Yakutsk',
        },
        {
          value: 'Australia/Adelaide',
          text: '(GMT+09:30) Adelaide',
        },
        {
          value: 'Australia/Darwin',
          text: '(GMT+09:30) Darwin',
        },
        {
          value: 'Australia/Brisbane',
          text: '(GMT+10:00) Brisbane',
        },
        {
          value: 'Australia/Melbourne',
          text: '(GMT+10:00) Canberra, Melbourne',
        },
        {
          value: 'Pacific/Guam',
          text: '(GMT+10:00) Guam',
        },
        {
          value: 'Australia/Hobart',
          text: '(GMT+10:00) Hobart',
        },
        {
          value: 'Pacific/Port_Moresby',
          text: '(GMT+10:00) Port Moresby',
        },
        {
          value: 'Australia/Sydney',
          text: '(GMT+10:00) Sydney',
        },
        {
          value: 'Asia/Vladivostok',
          text: '(GMT+10:00) Vladivostok',
        },
        {
          value: 'Asia/Magadan',
          text: '(GMT+11:00) Magadan',
        },
        {
          value: 'Pacific/Noumea',
          text: '(GMT+11:00) New Caledonia',
        },
        {
          value: 'Pacific/Guadalcanal',
          text: '(GMT+11:00) Solomon Is.',
        },
        {
          value: 'Asia/Srednekolymsk',
          text: '(GMT+11:00) Srednekolymsk',
        },
        {
          value: 'Pacific/Auckland',
          text: '(GMT+12:00) Auckland, Wellington',
        },
        {
          value: 'Pacific/Fiji',
          text: '(GMT+12:00) Fiji',
        },
        {
          value: 'Asia/Kamchatka',
          text: '(GMT+12:00) Kamchatka',
        },
        {
          value: 'Pacific/Majuro',
          text: '(GMT+12:00) Marshall Is.',
        },
        {
          value: 'Pacific/Chatham',
          text: '(GMT+12:45) Chatham Is.',
        },
        {
          value: 'Pacific/Tongatapu',
          text: '(GMT+13:00) Nukualofa',
        },
        {
          value: 'Pacific/Apia',
          text: '(GMT+13:00) Samoa',
        },
        {
          value: 'Pacific/Fakaofo',
          text: '(GMT+13:00) Tokelau Is.',
        },
      ],
    };
  },
};
