<template>
  <div class="general__page">
    <redirect class="" title="Settings" route="website.settings" />
    <v-row no-gutters>
      <v-col class="page-header" cols="12">
        <h2 class="page-title">General</h2>
      </v-col>
      <v-col cols="12" v-if="isLoading">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
      <v-col cols="12" v-else>
        <StoreDetail />
        <v-divider class="ml-3"></v-divider>
        <StoreAddress />
        <v-divider class="ml-3"></v-divider>
        <StandardsFormats />
        <v-divider class="ml-3"></v-divider>
        <language />
        <v-divider class="ml-3"></v-divider>
        <ScopeOfOperation />
      </v-col>
      <v-col cols="12" class="mt-5 text-end">
        <v-btn
          :disabled="isLoadingAction || isLoading"
          :loading="isLoadingAction || isLoading"
          @click="submit"
          color="primary"
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import StoreDetail from './components/StoreDetail';
import StoreAddress from './components/StoreAddress';
import StandardsFormats from './components/StandardsFormats';
// import StoreCurrency from './components/StoreCurrency';
import { settingsApi } from '@/apis/settings';
import event from '@/plugins/event-bus';

import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import Language from './components/Language.vue';
import ScopeOfOperation from './components/ScopeOfOperation.vue';
export default {
  components: {
    StoreDetail,
    StoreAddress,
    StandardsFormats,
    Language,
    ScopeOfOperation,
  },
  data() {
    return {
      general: {
        storeDetail: {
          name: '',
          accountEmail: '',
          customerEmail: '',
          telegramGroupIds: '',
          telegramJob: { newOrder: true, newAbandonedCheckout: false, storeError: false },
        },
        language: 'en',
        addressLegalName: '',
        addressPhone: '',
        address: '',
        addressAppartment: '',
        addressCity: '',
        addressCountry: '',
        addressPostalCode: '',
        standardTimezone: '',
        standardUnitWeight: '',
        standardDefaultWeightUnit: '',
        editOrderIdPrfeix: '',
        editOrderIdSuffix: '',
        currency: '',
        currencyFormatHtmlWithoutCurrency: '',
        currencyEmailWithoutCurrency: '',
        blacklistStates: [],
      },
      isLoading: false,
      isLoadingAction: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        let { data } = await settingsApi.getGeneral();
        this.general = data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async submit() {
      this.isLoadingAction = true;
      try {
        let { data } = await settingsApi.updateGeneral(this.general);
        switch (data.language) {
          case 'vi':
            localStorage.setItem('currencySign', 'đ');
            localStorage.setItem('currencyCode', 'VND');
            break;
          case 'en':
            localStorage.setItem('currencySign', '$');
            localStorage.setItem('currencyCode', 'USD');
            break;
          case 'eur':
            localStorage.setItem('currencySign', '€');
            localStorage.setItem('currencyCode', 'EUR');
            break;
        }

        this.$store.commit('setPagePreferrence', data);
        event.$emit('getStepStatus', message => {
          this.getStep();
        });
        this.isLoadingAction = false;
      } catch (error) {
        this.isLoadingAction = false;
      }
    },
  },
};
</script>
